<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'purchase-order-create'"
    header-left-class="purchase-order-heading-left"
    header-right-class="purchase-order-button-right"
    v-if="getPermission('purchase-order:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex" v-if="false">
        Create new Purchase Order
        <v-skeleton-loader
          v-if="pageLoading"
          class="custom-skeleton"
          type="text"
        ></v-skeleton-loader>
        <template v-else>{{ purchaseOrderCreate.barcode }}</template>
      </h1>
      <h1 class="form-title">
        <v-layout>
          <v-flex class="flex-grow-0"> PO For </v-flex>
          <v-flex
            disabled
            class="m-0 form-title-create-link pl-2 cursor-pointer text-h5"
            link
            :class="{ 'text-truncate': !lodash.isEmpty(customer) }"
            v-on:click="refDialogOpen"
          >
            <template v-if="lodash.isEmpty(customer)"
              >Select a Supplier</template
            >
            <template v-else>{{ customer.display_name }}</template>
            <v-icon
              link
              large
              color="cyan"
              class="mx-2"
              v-if="lodash.isEmpty(customer)"
              >mdi-account-check-outline</v-icon
            >
          </v-flex>
          <div class="mx-3">OR</div>
          <v-flex
            class="m-0 form-title-create-link pl-2 cursor-pointer text-h5"
          >
            <div
              class="form-title-link cursor-pointer cyan--text text--darken-4"
              v-on:click="createSupplierDialog()"
            >
              Create a new Supplier
              <v-icon large color="cyan darken-4"
                >mdi-plus-circle-outline</v-icon
              >
            </div>
          </v-flex>
        </v-layout>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        depressed
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        depressed
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate('')"
        color="cyan"
      >
        Save
      </v-btn>
      <template v-if="getPermission('purchase-order:approve')">
        <v-btn
          depressed
          :disabled="!formValid || formLoading || pageLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          v-on:click="updateOrCreate('approve')"
          color="cyan"
        >
          Save and Approve
        </v-btn>
      </template>
    </template>
    <template v-slot:body>
      <v-form
        ref="purchaseOrderForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate('')"
      >
        <v-container fluid>
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="position: relative"
          >
            <v-row>
              <v-col md="6">
                <v-row class="pa-1 grey lighten-4">
                  <v-col md="4">
                    <v-layout class="align-center">
                      <v-flex md5
                        ><label class="my-0"
                          ><b>Supplier Details</b></label
                        ></v-flex
                      >
                      <v-flex md7 class="position-relative">
                        <v-icon
                          v-on:click="refDialogOpen('supplier')"
                          small
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="true">
                      <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                        <v-icon small>mdi-account</v-icon>
                        Suraj Prasad
                      </p>
                      <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                        <v-icon small>mdi-phone</v-icon>
                        +65 1234 5678
                        <!-- {{ purchaseOrderCreate.supplier.phone }} -->
                      </p>
                      <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                        <v-icon small>mdi-email</v-icon>
                        suraj@gmail.com
                      </p>
                      <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                        <v-icon small>mdi-home-city-outline</v-icon>
                        Business Thrust Techsoft Pvt Ltd.
                      </p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select Supplier</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select Supplier</p>
                    </template>
                  </v-col>
                  <v-col md="4">
                    <v-layout class="align-center">
                      <v-flex md5
                        ><label class="my-0"
                          ><b>Contact Persons</b></label
                        ></v-flex
                      >
                      <v-flex md7 class="position-relative">
                        <v-icon
                          small
                          v-on:click="refDialogOpen('supplier')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>

                    <template v-if="true">
                      <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                        <v-icon small>mdi-account</v-icon>
                        John Doe
                      </p>
                      <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                        <v-icon small>mdi-phone</v-icon>
                        +65 1234 5678
                        <!-- {{ purchaseOrderCreate.supplier.phone }} -->
                      </p>
                      <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                        <v-icon small>mdi-email</v-icon>
                        john@gmail.com
                      </p>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select address</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select Supplier</p>
                    </template>
                  </v-col>
                  <v-col md="4">
                    <v-layout class="mt-3 align-center">
                      <v-flex md4
                        ><label class="my-0"><b>Addresss</b></label></v-flex
                      >
                      <v-flex md8 class="position-relative">
                        <v-icon
                          small
                          v-on:click="refDialogOpen('supplier')"
                          class="py-0 my-0"
                          color="cyan"
                          style="position: absolute; top: -8px"
                          >mdi-pencil</v-icon
                        >
                      </v-flex>
                    </v-layout>
                    <template v-if="true">
                      <template v-if="true">
                        <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                          <v-icon small>mdi-map</v-icon>
                          ATM, Bendemeer Road Branch (UOB),<br />
                          25 Bendemeer Road, #01-583,<br />
                          0 , 330025
                        </p>
                      </template>
                    </template>
                    <template v-else-if="vCustomer?.id">
                      <p class="mb-0 red--text">Please select contact person</p>
                    </template>
                    <template v-else>
                      <p class="mb-0 red--text">Please select Supplier</p>
                    </template>
                  </v-col>
                </v-row>
                <div class="" v-if="false">
                  <h3 class="font-weight-700 font-size-16 pb-2 mb-0 pl-1">
                    Supplier
                  </h3>
                  <v-autocomplete
                    dense
                    filled
                    v-model.number="purchaseOrderCreate.supplier"
                    :items="supplierList"
                    item-color="cyan"
                    color="cyan"
                    placeholder="Supplier"
                    solo
                    flat
                    return-object
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    item-value="id"
                    item-text="company_name"
                    :rules="[
                      validateRules.required(
                        purchaseOrderCreate.supplier,
                        'Supplier'
                      ),
                    ]"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          No Supplier(s) Found.
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  <v-layout
                    v-if="!lodash.isEmpty(purchaseOrderCreate.supplier)"
                  >
                    <v-flex class="pa-1 grey lighten-4">
                      <h3 class="font-weight-700 font-size-16">
                        Supplier Details
                      </h3>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.supplier &&
                          purchaseOrderCreate.supplier.display_name
                        "
                      >
                        <v-icon small>mdi-account</v-icon>
                        {{ purchaseOrderCreate.supplier.display_name }}
                      </p>
                      <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                        <v-icon small>mdi-phone</v-icon>
                        +65 1234 5678
                        <!-- {{ purchaseOrderCreate.supplier.phone }} -->
                      </p>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.supplier &&
                          purchaseOrderCreate.supplier.supplier_email
                        "
                      >
                        <v-icon small>mdi-email</v-icon>
                        {{ purchaseOrderCreate.supplier.supplier_email }}
                      </p>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.supplier &&
                          purchaseOrderCreate.supplier.company_name
                        "
                      >
                        <v-icon small>mdi-home-city-outline</v-icon>
                        {{ purchaseOrderCreate.supplier.company_name }}
                      </p>
                    </v-flex>
                    <v-flex class="pa-1 grey lighten-4">
                      <h3 class="font-weight-700 font-size-16">
                        Contact Person
                      </h3>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.supplier &&
                          purchaseOrderCreate.supplier.full_name
                        "
                      >
                        <v-icon small>mdi-account</v-icon>
                        {{ purchaseOrderCreate.supplier.full_name }}
                      </p>
                      <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                        <v-icon small>mdi-phone</v-icon>
                        +65 1234 5678
                        <!-- {{ purchaseOrderCreate.supplier.phone }} -->
                      </p>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.supplier &&
                          purchaseOrderCreate.supplier.supplier_email
                        "
                      >
                        <v-icon small>mdi-email</v-icon>
                        {{ purchaseOrderCreate.supplier.supplier_email }}
                      </p>
                    </v-flex>
                    <v-flex class="pa-1 grey lighten-4">
                      <h3 class="font-weight-700 font-size-16">
                        Company Address
                      </h3>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.supplier &&
                          purchaseOrderCreate.supplier.complete_address
                        "
                      >
                        <v-icon small>mdi-map</v-icon>
                        {{ purchaseOrderCreate.supplier.complete_address }}
                      </p>
                    </v-flex>
                  </v-layout>
                </div>
                <div class="pt-3" v-if="false">
                  <h3 class="font-weight-700 font-size-16 pb-2 mb-0 pl-1">
                    Customer
                  </h3>
                  <v-autocomplete
                    dense
                    filled
                    v-model.number="purchaseOrderCreate.customer"
                    :items="customerList"
                    item-color="cyan"
                    color="cyan"
                    placeholder="Customer"
                    solo
                    flat
                    return-object
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    item-value="id"
                    item-text="company_name"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          No Customer(s) Found.
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                  <!-- v-if="!lodash.isEmpty(purchaseOrderCreate.customer)" -->
                  <v-layout v-if="false">
                    <v-flex class="pa-1 grey lighten-4">
                      <h3 class="font-weight-700 font-size-16">
                        Customer Details
                      </h3>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.customer &&
                          purchaseOrderCreate.customer.display_name
                        "
                      >
                        <v-icon small>mdi-account</v-icon>
                        {{ purchaseOrderCreate.customer.display_name }}
                      </p>
                      <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                        <v-icon small>mdi-phone</v-icon>
                        +65 1234 5678
                        <!-- {{ purchaseOrderCreate.customer.phone }} -->
                      </p>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.customer &&
                          purchaseOrderCreate.customer.supplier_email
                        "
                      >
                        <v-icon small>mdi-email</v-icon>
                        {{ purchaseOrderCreate.customer.supplier_email }}
                      </p>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.customer &&
                          purchaseOrderCreate.customer.company_name
                        "
                      >
                        <v-icon small>mdi-home-city-outline</v-icon>
                        {{ purchaseOrderCreate.customer.company_name }}
                      </p>
                    </v-flex>
                    <v-flex class="pa-1 grey lighten-4">
                      <h3 class="font-weight-700 font-size-16">
                        Contact Person
                      </h3>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.customer &&
                          purchaseOrderCreate.customer.full_name
                        "
                      >
                        <v-icon small>mdi-account</v-icon>
                        {{ purchaseOrderCreate.customer.full_name }}
                      </p>
                      <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                        <v-icon small>mdi-phone</v-icon>
                        +65 1234 5678
                        <!-- {{ purchaseOrderCreate.customer.phone }} -->
                      </p>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.customer &&
                          purchaseOrderCreate.customer.supplier_email
                        "
                      >
                        <v-icon small>mdi-email</v-icon>
                        {{ purchaseOrderCreate.customer.supplier_email }}
                      </p>
                    </v-flex>
                    <v-flex class="pa-1 grey lighten-4">
                      <h3 class="font-weight-700 font-size-16">
                        Company Address
                      </h3>
                      <p
                        class="mb-0 font-size-16 custom-nowrap-ellipsis"
                        v-if="
                          purchaseOrderCreate &&
                          purchaseOrderCreate.customer &&
                          purchaseOrderCreate.customer.complete_address
                        "
                      >
                        <v-icon small>mdi-map</v-icon>
                        {{ purchaseOrderCreate.customer.complete_address }}
                      </p>
                    </v-flex>
                  </v-layout>
                </div>
              </v-col>
              <v-col md="6">
                <table class="width-100">
                  <tr>
                    <td colspan="3" class="font-weight-700 font-size-16 pb-2">
                      Purchase Order details
                    </td>
                  </tr>
                  <tr>
                    <td class="font-size-16 py-2" width="250">
                      Purchase Order number
                    </td>
                    <td class="font-weight-700 font-size-16 pb-2" width="150">
                      <v-skeleton-loader
                        v-if="pageLoading"
                        class="custom-skeleton"
                        type="text"
                      ></v-skeleton-loader>
                      <template v-else>
                        <span class="text-h5 font-weight-bold orange--text">
                          {{ purchaseOrderCreate.barcode }}
                        </span>
                      </template>
                    </td>
                    <td class="font-size-16 pb-2">
                      <!-- <v-btn
                        text
                        small
                        :disabled="pageLoading"
                        class="custom-bold-button"
                        v-on:click="barcodeDialog = true"
                        color="cyan"
                      >
                        Change
                      </v-btn> -->
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-2 font-size-16">Reference #</td>
                    <td colspan="2" class="py-2">
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="purchaseOrderCreate.reference"
                        placeholder="Reference #"
                        :rules="[
                          validateRules.minLength(
                            purchaseOrderCreate.reference,
                            'Reference #',
                            1
                          ),
                          validateRules.maxLength(
                            purchaseOrderCreate.reference,
                            'Reference #',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-2 font-size-16">Supplier Ref #</td>
                    <td colspan="2" class="py-2">
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        v-model.trim="purchaseOrderCreate.supplier_reference"
                        placeholder="Supplier Ref #"
                        :rules="[
                          validateRules.minLength(
                            purchaseOrderCreate.supplier_reference,
                            'Supplier Ref #',
                            1
                          ),
                          validateRules.maxLength(
                            purchaseOrderCreate.supplier_reference,
                            'Supplier Ref #',
                            100
                          ),
                        ]"
                        solo
                        flat
                        hide-details
                      ></v-text-field>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td class="pb-2 font-size-16">Project</td>
                    <td colspan="2" class="py-2">
                      <v-autocomplete
                        v-model.trim="purchaseOrderCreate.project"
                        :items="projectList"
                        dense
                        filled
                        clearable
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        color="cyan"
                        hide-details
                        item-color="cyan"
                        item-text="barcode"
                        item-value="id"
                        placeholder="Project"
                        solo
                        flat
                        v-on:change="
                          getOptions({ project: purchaseOrderCreate.project })
                        "
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Project Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr> -->
                  <tr>
                    <td class="pb-2 font-size-16">PO Date</td>
                    <td colspan="2" class="py-2">
                      <DatePicker
                        :placeholder="'Date'"
                        solo
                        :pageLoading="pageLoading"
                        v-on:update:date-picker="setPODate"
                      ></DatePicker>
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-2 font-size-16">Expected Delivery Date</td>
                    <td colspan="2" class="py-2">
                      <DatePicker
                        :placeholder="'Expected Delivery Date'"
                        solo
                        :pageLoading="pageLoading"
                        v-on:update:date-picker="setExpectedDate"
                      ></DatePicker>
                    </td>
                  </tr>
                  <tr>
                    <td class="pb-2 font-size-16">Payment Term</td>
                    <td colspan="2" class="py-2">
                      <v-select
                        dense
                        filled
                        :items="paymentTermList"
                        item-color="cyan"
                        color="cyan"
                        placeholder="Payment Term"
                        solo
                        flat
                        hide-details
                        class="d-flex align-center"
                        v-model.number="purchaseOrderCreate.payment_term"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        append-outer-icon="mdi-cog"
                        @click:append-outer="addPaymentTermDialog"
                        item-value="id"
                        item-text="text"
                        :rules="[
                          validateRules.required(
                            purchaseOrderCreate.payment_term,
                            'Payment Term'
                          ),
                        ]"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              No Payment Term(s) Found.
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-select>
                    </td>
                  </tr>
                  <tr v-if="purchaseOrderCreate.payment_term == 5">
                    <td class="pb-2 font-size-16">Due date</td>
                    <td colspan="2" class="py-2">
                      <DatePicker
                        :placeholder="'Due date'"
                        solo
                        :pageLoading="pageLoading"
                        v-on:update:date-picker="setDueDate"
                      ></DatePicker>
                    </td>
                  </tr>
                </table>
              </v-col>
              <v-col md="12" v-if="getPermission('line-item:create')">
                <v-container fluid>
                  <v-card flat class="custom-grey-border remove-border-radius">
                    <v-card-title class="headline grey lighten-4">
                      <h3
                        class="font-weight-700 custom-headline color-custom-blue"
                      >
                        Line Items
                      </h3>
                    </v-card-title>
                    <v-card-text class="p-6 font-size-16">
                      <v-row dense>
                        <v-col cols="12" class="pt-0 pb-0">
                          <POLineItem></POLineItem>
                          <!-- <PurchaseOrderLineItem
                            isPurchaseOrder
                            :updateData="updateLineItem"
                            :pageLoading="pageLoading"
                          ></PurchaseOrderLineItem> -->
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-col>
              <v-col md="12">
                <PurchaseOrderTermsConditions
                  :updateData="updateTermCondition"
                  :pageLoading="pageLoading"
                ></PurchaseOrderTermsConditions>
              </v-col>
              <v-col md="12">
                <PurchaseOrderNotesAttachment
                  isPurchaseOrder
                  :updateData="updateNotesAttachment"
                  :pageLoading="pageLoading"
                ></PurchaseOrderNotesAttachment>
              </v-col>
            </v-row>
          </perfect-scrollbar>
        </v-container>
      </v-form>
      <template>
        <supplier-create :key="`supplier-create-${dialog_key}`" />
      </template>
      <template v-if="refEntityId && refSupplierDialog">
        <RefSupplierDialog
          :entity="refEntityId"
          :supplier-dialog="refSupplierDialog"
          v-on:closeDialog="refCloseDialog"
          v-on:resetAll="refResetAll"
          v-on:selectSupplier="refSelectSupplier"
        ></RefSupplierDialog>
      </template>
      <PaymentTermDialog
        :d-dialog="addPaymentDialog"
        :e_paymentTerm="paymentTermData"
        v-on:close-dialog="addPaymentDialog = false"
      ></PaymentTermDialog>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="purchase-order"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting="barcodeSetting"
        v-on:update:barcode-setting="getOptions"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
    <template> </template>
  </CreateUpdateTemplate>
</template>
<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  POST,
  GET,
  QUERY,
} from "@/core/services/store/request.module";

import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
//import PurchaseOrderLineItem from "@/view/pages/partials/Line-Item.vue";
import POLineItem from "@/view/components/POLineItem.vue";
import PurchaseOrderTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import PurchaseOrderNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";
import SupplierCreate from "@/view/components/SupplierCreateDialog.vue";
import { SET_CREATE_DIALOG_STATUS } from "@/core/services/store/customer.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import RefSupplierDialog from "@/view/pages/partials/Select-Supplier.vue";
import PaymentTermDialog from "@/view/pages/partials/PaymentTermDialog.vue";
import ObjectPath from "object-path";
export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "purchase-order-create",
  title: "Create Task",
  data() {
    return {
      //formLoading: false,
      refEntityId: 1,
      addPaymentDialog: false,
      paymentTermData: [],
      e_paymentTerm: [],
      dialog_key: Number(new Date()),
      disableItem: true,
      pageLoading: false,
      barcodeDialog: false,
      refCustomerPropertyDialog: false,
      refSupplierDialog: false,
      customerBillingDialog: false,
      customerPropertyDialog: false,
      customerPersonDialog: false,
      customer: {},
      customerId: 0,
      refCustomerId: 0,
      refPropertyId: 0,
      purchaseOrderDuplicate: 0,
      updateNotesAttachment: new Object(),
      updateTermCondition: new Object(),
      projectList: new Array(),
      updateLineItem: new Array(),
      updateLineItemCalculation: new Object(),
      supplierList: [],
      poLineItems: [
        {
          isEditable: false,
          id: null,
          uuid: null,
          product: null,
          description: null,
          quantity: 1,
          quantity_type: null,
          discount: null,
          rate: 0,
          uom: null,
          tax_value: null,
          tax_amount: null,
          sub_total: 0,
          total: 0,
          // product_type: null,
          order: 0,
        },
      ],
      customerList: [
        {
          id: "1",
          barcode: "C-000003",
          company_name: "bthrust",
          display_name: "bthrust",
        },
        {
          id: "2",
          barcode: "C-000003",
          company_name: "ABC Company",
          display_name: "bthrust",
        },
      ],
      paymentTermList: [],
      notesAttachment: null,
      termCondition: null,
      lineItem: null,
      lineItemCalculation: null,
      barcodeSetting: new Object(),
      purchaseOrder: 0,
      purchaseOrderLineItems: new Array(),
      purchaseOrderCreate: new Object({
        id: null,
        supplier: null,
        customer: null,
        barcode: null,
        reference: null,
        supplier_reference: null,
        date: null,
        project: null,
        expected_delivery_date: null,
        due_date: null,
        payment_term: null,
        admin_remark: null,
        documents: [],
        term_conditions: null,
        additional_remarks: null,
        discount_type: null,
        discount_value_type: null,
        discount_value: null,
        adjustment: null,
      }),
    };
  },
  components: {
    DatePicker,
    BarcodeSetting,
    CreateUpdateTemplate,
    PurchaseOrderTermsConditions,
    PurchaseOrderNotesAttachment,
    //PurchaseOrderLineItem,
    POLineItem,
    SupplierCreate,
    RefSupplierDialog,
    PaymentTermDialog,
  },
  methods: {
    addPaymentTermDialog() {
      this.addPaymentDialog = true;
    },
    setPODate(param) {
      this.purchaseOrderCreate.date = param;
    },
    setExpectedDate(param) {
      this.purchaseOrderCreate.expected_delivery_date = param;
    },
    setDueDate(param) {
      this.purchaseOrderCreate.due_date = param;
    },
    createSupplierDialog() {
      this.dialog_key = Number(new Date());
      this.$nextTick(() => {
        this.$store.commit(SET_CREATE_DIALOG_STATUS, true);
      });
    },
    refSelectSupplier(param) {
      this.refCustomerId = param;
      this.$nextTick(() => {
        this.refSupplierDialog = false;
        // this.refCustomerPropertyDialog = true;
      });
    },
    refCloseDialog() {
      this.refSupplierDialog = false;
      this.refCustomerPropertyDialog = false;
    },
    refDialogOpen() {
      if (!this.quotationId) {
        this.refSupplierDialog = true;
      } else {
        return false;
      }
    },
    selectCustomer(param) {
      this.customerId = param;
      this.closeDialog();
      /*if (!this.quotationProperty || this.quotationProperty <= 0) {
        this.customerPropertyDialog = true;
      }*/
    },
    closeDialog() {
      this.supplierDialog = false;
      this.customerPersonDialog = false;
      this.customerBillingDialog = false;
      this.customerPropertyDialog = false;
    },
    refResetAll() {
      this.refEntityId = 1;
      this.refSupplierDialog = false;
      this.refCustomerPropertyDialog = false;
      this.refCustomerId = 0;
    },
    selectCustomerPerson(param) {
      if (this.person_type == "property") {
        if (this.quotationPropertyPerson == param) {
          this.closeDialog();
          return false;
        }
        this.quotationPropertyPerson = param;
      }
      if (this.person_type == "billing") {
        if (this.quotationBillingPerson == param) {
          this.closeDialog();
          return false;
        }
        this.quotationBillingPerson = param;
      }
      this.closeDialog();
      this.pushToRouteQuotation();
    },
    selectCustomerProperty(param) {
      if (this.quotationProperty == param) {
        this.closeDialog();
        return false;
      }
      this.forcePush = true;
      this.quotationProperty = param;
      this.closeDialog();
      this.pushToRouteQuotation();
    },
    selectCustomerBilling(param) {
      if (this.quotationBilling == param) {
        this.closeDialog();
        return false;
      }
      this.quotationBilling = param;
      this.closeDialog();
      this.pushToRouteQuotation();
    },
    refSelectCustomerProperty(param) {
      this.refPropertyId = param;
      /*   let route =  */ this.getDefaultRoute("purchase-order.create", {
        query: {
          customer: this.refCustomerId,
          property: this.refPropertyId,
          duplicate: ObjectPath.get(this.$route, "query.duplicate"),
          isDupChanged: 1,
        },
      });

      this.duplicateQuotation = ObjectPath.get(this.$route, "query.duplicate");
      (this.quotationProperty = this.refPropertyId),
        (this.quotationCustomer = this.refCustomerId),
        (this.isDupChanged = 1);
      this.pushToRouteQuotation();
      this.closeDialog();
      this.refCustomerPropertyDialog = false;
      /* const resolved = this.$router.resolve(route);
      window.location.href = resolved.href; */
    },
    getOptions(param) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "purchase-order/options",
          data: { ..._this.$route.query, ...param },
        })
        .then(({ data }) => {
          if (data.project) {
            _this.purchaseOrderCreate.project = data.project.id;
            _this.purchaseOrderCreate.supplier_reference =
              data.project.reference;
            _this.purchaseOrderCreate.reference = data.project.soled_job_no;
          }
          _this.projectList = data.projects;
          _this.barcodeSetting = data.barcode_setting;
          _this.purchaseOrderCreate.barcode = data.barcode;
          _this.supplierList = data.supplier;
          _this.paymentTermList = data.payment_term;
          _this.updateTermCondition = new Object({
            term_conditions: _this.barcodeSetting.terms_conditions,
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    async updateOrCreate(action) {
      try {
        const _this = this;
        if (!_this.$refs.purchaseOrderForm.validate()) {
          return false;
        }

        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});

        let formData = new Object({
          action,
          supplier: _this.purchaseOrderCreate.supplier.id,
          reference: _this.purchaseOrderCreate.reference,
          project: _this.purchaseOrderCreate.project,
          supplier_reference: _this.purchaseOrderCreate.supplier_reference,
          date: _this.purchaseOrderCreate.date,
          expected_delivery_date:
            _this.purchaseOrderCreate.expected_delivery_date,
          due_date: _this.purchaseOrderCreate.due_date,
          payment_term: _this.purchaseOrderCreate.payment_term,
          admin_remark:
            _this.lodash.isEmpty(_this.notesAttachment) === false
              ? _this.notesAttachment.admin_notes
              : null,
          documents:
            _this.lodash.isEmpty(_this.notesAttachment) === false
              ? _this.notesAttachment.documents
              : [],
          term_conditions: _this.termCondition,
          additional_remarks:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.description
              : null,
          discount_type:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountType
              : null,
          discount_value_type:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountValueType
              : null,
          discount_value:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountValue
              : null,
          adjustment:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.adjustmentAmount
              : null,
        });

        if (!_this.purchaseOrderCreated) {
          try {
            let purchaseOrder = await _this.$store.dispatch(POST, {
              url: "purchase-order",
              data: formData,
            });

            _this.purchaseOrder = _this.lodash.toSafeInteger(
              purchaseOrder.data.id
            );

            _this.purchaseOrderCreated = true;
          } catch (error) {
            _this.logError(error);
            _this.purchaseOrderCreated = false;
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Purchase Order is not created. Please try again."
              )
            );
          }
        }

        if (
          !_this.lineItemCreated &&
          _this.purchaseOrderCreated &&
          _this.purchaseOrder
        ) {
          try {
            await _this.CreateLineItems({
              type: "purchase_order",
              parent: _this.purchaseOrder,
              formData: _this.lineItem,
            });

            _this.lineItemCreated = true;
          } catch (error) {
            _this.logError(error);
            _this.lineItemCreated = false;
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Purchase Order Line Item(s) are not created. Please try again."
              )
            );
          }
        }

        _this.formLoading = false;

        if (_this.purchaseOrderCreated && _this.lineItemCreated) {
          _this.$router.push(
            _this.getDefaultRoute("purchase.order.detail", {
              params: {
                id: _this.purchaseOrder,
              },
            })
          );
        }
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error.toString()));
      }
    },
    getPurchaseOrder(purchaseOrder) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "purchase-order/" + purchaseOrder,
        })
        .then(({ data }) => {
          _this.purchaseOrderCreate.supplier =
            _this.lodash.isEmpty(data.supplier) === false
              ? data.supplier
              : null;
          _this.purchaseOrderCreate.payment_term =
            _this.lodash.isEmpty(data.payment_term) === false
              ? data.payment_term.id
              : null;

          let lineItems = new Array();
          for (let i = 0; i < data.line_items.length; i++) {
            lineItems.push({
              original_product: data.line_items[i].original_product,
              id: null,
              description: data.line_items[i].description,
              quantity: data.line_items[i].quantity,
              tax: data.line_items[i].tax,
              rate: data.line_items[i].rate,
              total: data.line_items[i].total,
              warranty_data: null,
              warranty_start_date: null,
              warranty_unique_id: null,
              warranty_description: null,
              minimum_rental: null,
              maximum_rental: null,
            });
          }

          _this.updateLineItem = lineItems;

          _this.updateLineItemCalculation = new Object({
            additional_remarks: data.additional_remarks,
            sub_total: data.sub_total,
            tax_amount: data.tax_amount,
            discount_value: data.discount_value,
            discount_type: data.discount_type,
            discount_value_type: data.discount_value_type,
            adjustment: data.adjustment,
            total: data.total,
          });

          _this.updateNotesAttachment = new Object({
            admin_remark: data.admin_remark,
            client_remark: null,
            notify_admin: 0,
            notify_customer: 0,
            notify_engineer: 0,
          });

          _this.updateTermCondition = new Object({
            term_conditions: data.term_conditions,
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  beforeDestroy() {
    /*LineItemEventBus.$off("update:line-item");
    LineItemCalculationEventBus.$off("update:line-item-calculation");
    NoteAttachmentEventBus.$off("update:notes-attachment");
    TermConditionEventBus.$off("update:term-condition");*/
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Purchase Order", route: "purchase.order" },
      { title: "Create" },
    ]);

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      _this.notesAttachment = argument;
    });

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      _this.termCondition = argument;
    });

    LineItemEventBus.$on("update:line-item", (argument) => {
      _this.lineItem = argument;
    });

    LineItemCalculationEventBus.$on(
      "update:line-item-calculation",
      (argument) => {
        _this.lineItemCalculation = argument;
      }
    );
  },
  created() {
    const _this = this;

    _this.purchaseOrderDuplicate = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
    if (_this.purchaseOrderDuplicate > 0) {
      _this.getPurchaseOrder(_this.purchaseOrderDuplicate);
    }
  },
  computed: {
    ...mapGetters([
      "vCustomer",
      "vBilling",
      "vBillingContact",
      "vPropertyContact",
      "vProperty",
    ]),
    defaultStartDate() {
      if (this.purchaseOrderCreate.start_date) {
        return this.purchaseOrderCreate.start_date;
      }
      return new Date().toISOString().substr(0, 10);
    },
    defaultDueDate() {
      if (this.purchaseOrderCreate.due_date) {
        return this.purchaseOrderCreate.due_date;
      }
      let today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    _this.routePreventDialog = true;
    PreventDialogEventBus.$on("stay-on-page", function () {
      _this.routePreventDialog = false;
      next(false);
    });
    PreventDialogEventBus.$on("leave-page", function () {
      _this.routePreventDialog = false;
      next();
    });
  },
};
</script>
<style scoped>
.perfect-scroll-create-container {
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
}
</style>
