<template>
  <div>
    <Dialog :commonDialog="dDialog" :dialogWidth="dialogWidth">
      <template v-slot:title>
        <v-layout>
          <v-flex> CREATE NEW PRODUCT </v-flex>
        </v-layout>
      </template>
      <template v-slot:body>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <v-form
            ref="ticketCollecttionForm"
            v-model.trim="formValid"
            lazy-validation
          >
            <v-container class="py-0">
              <v-row class="py-0">
                <v-col md="12" class="py-0">
                  <div class="d-flex">
                    <label
                      class="pr-2 font-weight-700 required font-size-16 width-100"
                      >Name</label
                    >
                  </div>
                  <div class="d-flex">
                    <TextInput
                      class="mb-3 ml-0"
                      :hideTopMargin="true"
                      hideDetails
                      v-model.trim="productCreate.name"
                      :rules="[
                        validateRules.required(productCreate.name, 'Name'),
                        validateRules.minLength(productCreate.name, 'Name', 1),
                        validateRules.maxLength(
                          productCreate.name,
                          'Name',
                          100
                        ),
                      ]"
                      :suffix="`${
                        productCreate.name ? productCreate.name.length : 0
                      }/50`"
                    ></TextInput>
                  </div>
                </v-col>
                <v-col md="12" class="py-0">
                  <div class="d-flex">
                    <label class="pr-2 font-weight-700 font-size-16 width-100"
                      >Category</label
                    >
                  </div>
                  <div class="d-flex">
                    <v-autocomplete
                      dense
                      filled
                      color="cyan"
                      item-color="cyan"
                      :items="categoryList"
                      label="Category"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageCategoryDialog = true"
                      item-text="text"
                      item-value="text"
                      class="width-100 d-flex align-baseline"
                      v-model.trim="productCreate.category"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Category(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </div>
                </v-col>
                <v-col md="12" class="py-0">
                  <v-row class="mt-0">
                    <v-col md="6" class="py-0">
                      <label
                        for="supplier fw-500"
                        class="btx-label mt-2 required"
                        >Supplier
                        <!-- <pre>{{ vendorList }}</pre> -->
                      </label>
                    </v-col>
                    <v-col md="3" class="py-0">
                      <label for="supplier-part-number" class="btx-label mt-2"
                        >Supplier Code</label
                      >
                    </v-col>
                    <v-col md="3" class="py-0">
                      <label
                        for="supplier-price"
                        class="btx-label mt-2 required"
                        >Purchasing Price</label
                      >
                    </v-col>
                  </v-row>
                  <v-row class="mb-3">
                    <v-col md="6" class="py-0">
                      <v-autocomplete
                        hide-details
                        solo
                        flat
                        dense
                        :items="supplierList(productCreate.supplier_id)"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier"
                        placeholder="Supplier"
                        v-model="productCreate.supplier_id"
                        :rules="[
                          validateRules.required(
                            productCreate.supplier_id,
                            'Supplier'
                          ),
                        ]"
                        class="mt-0"
                      >
                        <!-- @change="saveSupplierToLocal()" -->
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="'No Supplier Found.'"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col md="3" class="py-0">
                      <v-text-field
                        hide-details
                        dense
                        solo
                        flat
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier-part-number"
                        placeholder="Supplier Code"
                        v-model="productCreate.part_number"
                        class="mt-0"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" class="py-0">
                      <v-text-field
                        :min="0"
                        oninput="if(this.value < 0) this.value = 0;"
                        type="number"
                        hide-details
                        dense
                        solo
                        flat
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        id="supplier-price"
                        placeholder="Purchasing Price"
                        prepend-inner-icon="mdi-currency-usd"
                        v-model="productCreate.supplier_price"
                        :rules="[
                          validateRules.required(
                            productCreate.supplier_price,
                            'Purchasing Price'
                          ),
                        ]"
                        class="mt-0"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </perfect-scrollbar>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          :disabled="pageLoading || !formValid"
          :loading="pageLoading"
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
        >
          Save
        </v-btn>
        <v-btn
          depressed
          :disabled="pageLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="$emit('close-dialog', true)"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
    <ManageCategory
      product
      :c-dialog="manageCategoryDialog"
      :category="categoryList"
      v-on:close-dialog="manageCategoryDialog = false"
      v-on:get-product-category="getOptions"
    ></ManageCategory>
  </div>
</template>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import TextInput from "@/view/components/TextInput";
import ValidationMixin from "@/core/plugins/validation-mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import { cloneDeep, filter } from "lodash";
import { mapGetters } from "vuex";
import { QUERY, UPDATE_ERROR } from "@/core/services/store/request.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";
export default {
  mixins: [ValidationMixin, CommonMixin, ProductMixin],
  props: {
    dDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    e_product: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
    existCategory: {
      type: Number,
      default: null,
    },
  },
  watch: {
    e_product: {
      deep: true,
      immediate: true,
      handler(param) {
        this.productCreate = cloneDeep(param);
      },
    },
  },
  data: () => {
    return {
      formValid: true,
      pageLoading: false,
      manageCategoryDialog: false,
      categoryList: new Array(),
      vendorList: [],
      productCreate: {
        name: null,
        category: null,
        product_id: null,
        supplier_id: null,
        supplier_price: null,
        part_number: null,
      },
    };
  },
  methods: {
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
        })
        .then((response) => {
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.brand
          ) {
            _this.brandList = response.data.brand;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.manufacturer
          ) {
            _this.manufacturerList = response.data.manufacturer;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax
          ) {
            _this.taxList = response.data.tax;
            if (!_this.productCreate.tax) {
              let index = _this.lodash.findIndex(
                response.data.tax,
                function (tax) {
                  return tax.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax = _this.taxList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.taxExemption
          ) {
            _this.taxExemptionList = response.data.taxExemption;
            if (!_this.productCreate.tax_exemption) {
              let index = _this.lodash.findIndex(
                response.data.taxExemption,
                function (taxExemption) {
                  return taxExemption.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax_exemption =
                  _this.taxExemptionList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;
            //  console.log(_this.unitList);
          }

          if (!_this.updateMode) {
            if (
              !_this.productId &&
              _this.lodash.isEmpty(response.data) === false &&
              response.data.barcode
            ) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            if (_this.proDuplicateId) {
              _this.barcode = response.data.barcode;
              _this.productCreate.barcode = response.data.barcode;
            }

            _this.productCreate.serial_number = response.data.serial_number;
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    Categorydata() {
      this.manageCategoryDialog = true;
      this.getOptions();
    },
  },
  components: {
    Dialog,
    TextInput,
    ManageCategory,
  },
  mounted() {
    this.$store.dispatch(SET_LOCAL_DB).then(() => {
      this.vendorList = this.$store.state.config.localDB["data"]["suppliers"]; //this.localDB("suppliers", []);
    });

    this.getOptions();
  },
  computed: {
    ...mapGetters(["errors", "localDB"]),
    supplier_list() {
      return this.localDB("suppliers", []);
    },
    supplierList: () => {
      return function (id) {
        let _vendorList = cloneDeep(this.vendorList);
        const newArray = filter(_vendorList, (row) => {
          if (row.value != id) {
            if (
              !find(
                this.productCreate.suppliers,
                (inner_row) => inner_row.supplier_id == row.value
              )
            ) {
              return row;
            }
          } else {
            return row;
          }
        });
        return newArray;
      };
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
