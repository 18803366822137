<template>
  <div class="w-100 po-line-items">
    <v-layout class="py-0">
      <v-spacer></v-spacer>
      <v-col lg="3" md="4" sm="6" class="d-flex">
        <v-text-field
          hide-details
          solo
          dense
          filled
          flat
          outlined
          :disabled="pageLoading"
          :loading="pageLoading"
          v-model="search_key"
          class="mt-0 pt-0"
          style="padding-left: 10px !important"
          @click:clear="clearSearchLineItem()"
          placeholder="Search Line Item..."
          clearable
        ></v-text-field>
        <v-btn
          min-height="32"
          tile
          depressed
          color="blue darken-4 white--text"
          v-on:click="searchLineItem()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-layout>
    <table width="100%">
      <thead>
        <tr>
          <th class="pa-2" width="40"></th>
          <th class="pa-2 text-center" width="40">#</th>
          <th class="pa-2" width="300">Product</th>
          <th class="pa-2" width="300">Description</th>
          <th class="pa-2" width="150">UOM</th>
          <th class="pa-2" width="120">Qty</th>
          <th class="pa-2 text-right" width="120">Rate</th>
          <th class="pa-2 text-right" width="120">Amount</th>
          <th class="pa-2 text-right" width="40"></th>
        </tr>
      </thead>
      <!-- <tbody > -->
      <Draggable
        tag="tbody"
        v-model="line_items"
        class="draggable-group"
        handle=".draggable-drag-icon"
        v-clickoutside="outsideClicked"
        v-on:change="updateItemOrder($event)"
      >
        <tr
          v-for="(row, index) in flaggedLineItems"
          :key="index + line_items.length"
          class=""
        >
          <td class="py-1 px-2">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <inline-svg
                  v-bind="attrs"
                  v-on="on"
                  :src="$assetURL('media/custom-svg/dot2.svg')"
                />
              </template>
              <span
                >Supplier had removed from this product. You can remove this
                product</span
              >
            </v-tooltip>
          </td>
          <td class="py-1 px-2 text-center red--text darken-1">
            {{ index + 1 }}
          </td>
          <td
            class="py-1 px-2 red--text darken-1"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            {{ row.product_name }}
          </td>
          <td
            style="max-width: 300px"
            class="py-1 px-2 text-truncate red--text darken-1"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            <template class="px-1">{{ row.description }}</template>
          </td>
          <td
            class="py-1 px-2 red--text darken-1"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            <template class="px-1">{{ row.uom }}</template>
          </td>
          <td
            class="py-1 px-2 red--text darken-1"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            <template class="px-1">{{ row.quantity }}</template>
          </td>
          <td
            class="py-1 px-2 text-right red--text darken-1"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            <template class="px-1">{{ formatMoney(row.rate) }}</template>
          </td>
          <td
            class="py-1 px-2 text-right red--text darken-1"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            <template class="px-1">{{ formatMoney(row.total) }}</template>
          </td>
          <td class="py-1 px-2 text-right">
            <v-icon
              color="red"
              class="cursor-pointer"
              @click="deleteLineItems('flag_line_items', row, index)"
              >mdi-delete-outline</v-icon
            >
          </td>
        </tr>

        <tr v-for="(row, index) in line_items" :key="index" class="">
          <td class="py-1 px-2">
            <v-icon
              class="draggable-drag-icon cursor-move"
              color="blue darken-4"
              >mdi-drag</v-icon
            >
          </td>
          <td class="py-1 px-2 text-center">
            {{ flaggedLineItems.length + index + 1 }}
          </td>
          <td
            class="py-1 px-2"
            @click="editRow($event, index)"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            <v-autocomplete
              v-if="row.isEditable"
              solo
              dense
              filled
              flat
              outlined
              hide-details
              :items="productList"
              :disabled="pageLoading"
              :loading="pageLoading"
              id="Product"
              placeholder="Product"
              v-model="row.product"
              class="mt-0 d-flex align-center"
              append-outer-icon="mdi-plus"
              @click:append-outer="addProductDialog"
              v-on:change="
                getProductPrice(row, index),
                  updateLineItem(row, index),
                  getDiscount()
              "
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-content dense class="text-truncate">
                    <v-list-item-title
                      v-html="data.item.text"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon dense class="my-2 pl-2 pr-0" v-if="false">
                    <v-chip
                      :class="`px-1 white--text ${
                        data.item.product_type == 'inventory'
                          ? 'orange'
                          : 'blue'
                      }`"
                      label
                      small
                    >
                      <span class="text-capitalize">
                        {{
                          data.item.product_type == "inventory"
                            ? "product"
                            : data.item.product_type
                        }}
                      </span>
                    </v-chip>
                  </v-list-item-icon>
                </template>
              </template>
            </v-autocomplete>
            <template v-else>{{ row.product }}</template>
          </td>
          <td
            style="max-width: 300px"
            class="py-1 px-2 text-truncate"
            @click="editRow($event, index)"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            <v-text-field
              solo
              dense
              filled
              flat
              outlined
              v-if="row.isEditable"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              v-model="row.description"
              class="mt-0"
              @blur="updateLineItem(row, index), getDiscount()"
            ></v-text-field>
            <template v-else>{{ row.description }}</template>
          </td>
          <td
            class="py-1 px-2"
            @click="editRow($event, index)"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            <v-select
              solo
              dense
              filled
              flat
              outlined
              v-if="row.isEditable"
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              id="uom"
              @blur="updateLineItem(row, index)"
              hide-top-margin
              placeholder="uom"
              class="mt-0"
              v-model="row.uom"
              v-on:change="changeClicked()"
            ></v-select>
            <template v-else>{{ row.uom }}</template>
          </td>
          <td
            class="py-1 px-2"
            @click="editRow($event, index)"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            <QuantityInput
              outlined
              v-if="row.isEditable"
              hide-details
              :hideMargin="true"
              :disabled="pageLoading"
              :loading="pageLoading"
              v-model="row.quantity"
              class="mt-0 px-0 text-right"
              @blur="updateLineItem(row, index), getDiscount()"
              type="number"
              hide-spin-buttons
              v-on:keyup="getProductTotal()"
            ></QuantityInput>
            <template v-else>{{ row.quantity }}</template>
          </td>
          <td
            class="py-1 px-2 text-right"
            @click="editRow($event, index)"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            <div class="d-flex justify-space-between">
              <v-text-field
                solo
                dense
                filled
                flat
                outlined
                v-if="row.isEditable"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                v-model.number="row.rate"
                reverse
                class="mt-0"
                type="number"
                hide-spin-buttons
                v-on:keyup="getProductTotal()"
                v-on:blur="fixedOnBlur(), updateLineItem(row, index)"
                v-on:keydown.tab.prevent="tabClicked($event, index)"
                append-icon="mdi-currency-usd"
              ></v-text-field>
              <template>
                {{ formatMoney(row.rate) }}
              </template>
              <div
                style="min-width: 20px"
                class="ml-2 d-flex justify-start"
                v-if="row.is_price_difference"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="font-size: 12px !important"
                      v-if="row.is_price_difference"
                      small
                      color="red"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-circle
                    </v-icon>
                  </template>
                  <span>{{ row.is_price_difference_message }}</span>
                </v-tooltip>
              </div>
            </div>
          </td>
          <td
            class="py-1 px-2 text-right"
            @click="editRow($event, index)"
            :class="row.isEditable ? 'px-2' : 'px-3'"
          >
            <template v-if="row.total">{{ formatMoney(row.total) }}</template>
            <template v-else>{{ formatMoney(0) }}</template>
          </td>
          <td class="py-1 px-2 text-right">
            <v-icon
              color="red"
              :disabled="line_items.length == 1 ? true : false"
              class="cursor-pointer"
              @click="deleteLineItems('line_items', row, index)"
              >mdi-delete-outline</v-icon
            >
          </td>
        </tr>
      </Draggable>
      <!-- </tbody> -->
      <tfoot>
        <tr>
          <td class="pa-2 borer-none" colspan="4">
            <v-btn
              @click="addLineItems()"
              class="white--text"
              depressed
              color="blue darken-4"
              tile
            >
              <v-icon left>mdi-plus</v-icon>
              Add Line item
            </v-btn>
          </td>
          <td class="pa-2 borer-none text-right" colspan="3">
            <b>Sub Total</b>
          </td>
          <td class="pa-2 borer-none text-right" width="180">
            <!-- {{ formatMoney(sub_total) }} -->
            $0.00
          </td>
          <td class="pa-2 borer-none text-right" width="40"></td>
        </tr>
        <tr>
          <td class="pa-2 borer-none text-right" colspan="7">
            <div class="d-flex align-items-center justify-content-end">
              <b>Discount</b>
              <div style="max-width: 99px" class="ml-2">
                <QuantityInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model="discount_value"
                  type="number"
                  class="mt-0"
                  v-on:blur="getDiscount()"
                  v-on:change="getDiscount()"
                ></QuantityInput>
              </div>
              <div style="max-width: 60px" class="">
                <v-autocomplete
                  solo
                  dense
                  filled
                  flat
                  outlined
                  hide-details
                  :items="discountTypeList"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model="discount_value_type"
                  class="mt-0 text-center"
                  v-on:change="getDiscount()"
                ></v-autocomplete>
              </div>
            </div>
          </td>
          <td class="pa-2 borer-none text-right" width="180">
            <!-- {{ formatMoney(this.discount_amount) }} -->
            $0.00
          </td>
          <td class="pa-2 borer-none text-right" width="40"></td>
        </tr>
        <tr>
          <td class="pa-2 borer-none text-right" colspan="7">
            <div class="d-flex align-items-center justify-content-end">
              <v-checkbox
                hdie-details
                v-model="tax_applied"
                class="ma-0 pt-0"
                hide-details
                small
                id="is-tax"
                v-on:change="getTax()"
                dense
                color="blue darken-4"
              ></v-checkbox>
              <b>Tax</b>
              <div style="max-width: 60px" class="ml-2">
                <span class="fw-600" style="font-size: 16px">
                  {{ tax_value }} %
                </span>
                <AutoCompleteInput
                  v-if="false"
                  hide-details
                  :items="taxList"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model="tax_value"
                  class="mt-0 text-center"
                ></AutoCompleteInput>
              </div>
            </div>
          </td>
          <td class="pa-2 borer-none text-right" width="180">
            $0.00
            <!-- {{ formatMoney(getTax()) }} -->
          </td>
          <td class="pa-2 borer-none text-right" width="40"></td>
        </tr>
        <tr>
          <td class="pa-2 borer-none text-right" colspan="7">
            <div class="d-flex align-items-center justify-content-end">
              <b>Adjustment</b>
              <div style="max-width: 60px" class="ml-2">
                <v-text-field
                  solo
                  dense
                  filled
                  flat
                  outlined
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  type="number"
                  v-on:blur="getAdjustmentAmount()"
                  v-on:change="getAdjustmentAmount()"
                  v-model="adjustment"
                  class="mt-0"
                ></v-text-field>
              </div>
            </div>
          </td>
          <td class="pa-2 borer-none text-right" width="180">
            <!-- {{ formatMoney(adjustment_amount) }} -->
            $0.00
          </td>
          <td class="pa-2 borer-none text-right" width="40"></td>
        </tr>
        <tr>
          <td class="pa-2 borer-none text-right" colspan="7">
            <b style="font-size: 18px; font-weight: 700 !important"
              >Grand Total</b
            >
          </td>
          <td class="pa-2 borer-none text-right" width="180">
            <b style="font-size: 18px; font-weight: 700 !important">
              $0.00
              <!-- {{ formatMoney(getGrandTotal()) }} -->
            </b>
          </td>
          <td class="pa-2 borer-none text-right" width="40"></td>
        </tr>
      </tfoot>
    </table>
    <Dialog
      :dialog="importDialog"
      v-on:close="importDialog = false"
      v-if="importDialog"
    >
      <template v-slot:title> Upload Excel File </template>
      <template v-slot:body>
        <v-row>
          <v-col md="4" class="py-0">
            <label for="upload-file" class="btx-label mt-3">Select File</label>
          </v-col>
          <v-col md="8" class="py-0">
            <!-- <FileUpload
							:disabled="pageLoading"
							:loading="pageLoading"
							id="upload-file"
							placeholder="File"
							:allow-add-more="false"
							v-model="upload_excel.file"
						></FileUpload> -->
            <p class="mt-2 red--text" v-if="fileError">
              {{ fileError }}
            </p>
          </v-col>
          <v-col md="4" class="py-0 mt-3">
            <label for="upload-type" class="btx-label mt-4"
              >Download Sample For Line Items</label
            >
          </v-col>
          <v-col md="8" class="py-0 mt-3 d-flex">
            <a
              class="blue--text text--darken-4 fw-500 mt-4"
              style="text-decoration: underline"
              @click="getPurchaseOrderItemsSample"
              target="_blank"
              download
              >Download Sample</a
            >
          </v-col>
          <v-col md="12">
            <div v-if="backEndError" class="mt-2 red--text">
              <div
                class="mt-1"
                v-for="(error, index) in backEndError"
                :key="index"
              >
                {{ error }}
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="pageLoading"
          :loading="pageLoading"
          depressed
          tile
          @click="(importDialog = false), (backEndError = [])"
        >
          Close</v-btn
        >
        <v-btn
          class="white--text"
          :disabled="pageLoading"
          :loading="pageLoading"
          depressed
          color="blue darken-4"
          tile
          @click="uploadLineItems()"
        >
          Upload File</v-btn
        >
      </template>
    </Dialog>
    <Dialog
      :dialog="deleteConfirmationDialog"
      v-on:close="deleteConfirmationDialog = false"
      v-if="deleteConfirmationDialog"
      :dialog-width="500"
    >
      <template v-slot:title> Are you sure? </template>
      <template v-slot:body>
        <v-row>
          <v-col md="12" class="py-0">
            <p class="mt-0" style="line-height: 18px">
              Are you sure want to delete line item?
              <br />
              Line item can not be recovered once deleted?
            </p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="deleteConfirmationLoading"
          depressed
          tile
          @click="deleteConfirmationDialog = false"
        >
          NO</v-btn
        >
        <v-btn
          :disabled="deleteConfirmationLoading"
          :loading="deleteConfirmationLoading"
          class="white--text"
          depressed
          color="blue darken-4"
          tile
          @click="yesDeleteLineItem()"
        >
          YES
        </v-btn>
      </template>
    </Dialog>
    <AddProductDialog
      :d-dialog="addPaymentDialog"
      :e_product="paymentTermData"
      v-on:close-dialog="addPaymentDialog = false"
    ></AddProductDialog>
    <!-- <LineItemDialog
      :product-dialog="product_dialog"
      v-on:update-values="update_line_item($event)"
      v-on:close="product_dialog = false"
    /> -->
  </div>
</template>
<script>
//import TextInput from "@/src/view/components/TextInput";
//import PriceInput from "@/src/view/components/PriceInput";
//import LineItemDialog from "@/view/components/LineItemDialog.vue";
import Dialog from "@/view/components/Dialog";
import QuantityInput from "@/view/componentsOld/QuantityInput";
import Draggable from "vuedraggable";
import AddProductDialog from "@/view/pages/partials/AddProductDialog.vue";
//import FileUpload from "@/view/components/FileUpload";
export default {
  name: "po-line-items",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    supplier: {
      type: Number,
      default: 0,
    },
    show_only_supplier_products: {
      type: Number,
    },
    flaggedLineItems: {
      type: Array,
      default() {
        return [];
      },
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    value: {
      handler(param) {
        this.line_items = param;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      addPaymentDialog: false,
      paymentTermData: {},
      e_product: {},
      tax_applied: true,
      pageLoading: false,
      deleteConfirmationDialog: false,
      deleteConfirmationLoading: false,
      createProductDialog: false,
      deleteId: null,
      deleteIndex: -1,
      search_key: null,
      isOutside: true,
      backEndError: [],
      is_sheet_uploaded: 0,
      fileError: null,
      line_items: [
        {
          isEditable: false,
          product: null,
          description: null,
          quantity: 1,
          order: 1,
          uom: null,
          rate: 0,
          total: 0,
        },
      ],
      importDialog: false,
      upload_excel: {
        type: null,
        file: null,
      },
      taxList: [8],
      tax_value: 8,
      taxable_amount: 0,
      tax_amount: 0,
      discount_value: 0,
      discount_amount: 0,
      discount_value_type: 1,
      discount_type: 1,
      adjustment: 0,
      adjustment_amount: 0,
      sub_total: 0,
      total: 0,
      discountTypeList: [
        { text: "$", value: 1 },
        { text: "%", value: 2 },
      ],
      productList: [],
      allProductList: [],
    };
  },
  methods: {
    addProductDialog() {
      this.addPaymentDialog = true;
    },
    tabClicked(event, index) {
      /* console.log({ index: index, event: event }); */
      if (this.line_items.length == index + 1) {
        this.addLineItems();
      }

      const _line_items = this.line_items.map((row) => {
        return { ...row, isEditable: false };
      });
      this.line_items = _line_items;
      this.line_items[index + 1].isEditable = true;
      this.$nextTick(() => {
        // const inputarea = element.querySelector("input");
        // if (inputarea) {
        // 	inputarea.focus();
        // 	inputarea.select();
        // }
      });
    },
    changeClicked() {
      this.isOutside = true;
      //console.log("changeClicked");
    },
    outsideClicked() {
      if (!this.isOutside) {
        const _line_items = this.line_items.map((row) => {
          return { ...row, isEditable: false };
        });
        this.line_items = _line_items;
        this.isOutside = true;
      }
    },
    editRow(event, index) {
      this.isOutside = false;
      const element = event.target;
      //console.log(element.nodeName);
      const _line_items = this.line_items.map((row) => {
        return { ...row, isEditable: false };
      });
      this.line_items = _line_items;
      this.line_items[index].isEditable = true;
      this.$nextTick(() => {
        const inputarea = element.querySelector("input");
        if (inputarea) {
          inputarea.focus();
          inputarea.select();
        }
      });
      if (element.nodeName == "INPUT") {
        element.select();
      }
      // if (this.line_items.length == index + 1) {
      // 	this.addLineItems();
      // }
    },
    formatMoney() {},
    getTax() {
      if (this.tax_applied) {
        const _taxable_amount =
          Number(this.sub_total) - Number(this.discount_amount);
        this.tax_amount = Number((_taxable_amount * this.tax_value) / 100);
        this.taxable_amount = _taxable_amount;
      } else {
        this.tax_amount = 0;
      }
      return this.tax_amount;
    },
    getGrandTotal() {
      this.getDiscount();
      this.total =
        Number(this.sub_total) +
        Number(this.tax_amount) +
        Number(this.adjustment) -
        Number(this.discount_amount);
      this.updateCalculation();
      return this.total;
    },
    getDiscount() {},
    addLineItems() {
      const _order = this.line_items.length;
      this.line_items.push({
        isEditable: false,
        id: null,
        uuid: null,
        product: null,
        description: null,
        uom: null,
        quantity: 1,
        rate: 0,
        total: 0,
        order: _order + 1,
      });
      this.$emit("input", this.line_items);
    },
    deleteLineItems(index) {
      this.line_items.splice(index, 1);
    },
    updateCalculation() {
      this.$emit("update:total", {
        tax_value: this.tax_value,
        taxable_amount: this.taxable_amount,
        tax_amount: this.tax_amount,
        discount_value: this.discount_value,
        discount_value_type: this.discount_value_type,
        tax_applied: this.tax_applied ? 1 : 0,
        discount_amount: this.discount_amount,
        discount_type: this.discount_type,
        adjustment: this.adjustment,
        adjustment_amount: this.adjustment_amount,
        sub_total: this.sub_total,
        total: this.total,
      });
      this.$emit("input", this.line_items);
      //this.saveToLocalLineItem();
    },
  },
  components: {
    Draggable,
    //FileUpload,
    Dialog,
    QuantityInput,
    AddProductDialog,
    //PriceInput,
    //LineItemDialog,
  },
};
</script>
<style scoped>
.po-line-items th {
  font-weight: 500;
}
.po-line-items th,
.po-line-items td:not(.borer-none) {
  border-bottom: 1px solid #c7c7c7;
  border-left: 1px solid #c7c7c7;
  height: 40px;
}
.po-line-items th:first-child,
.po-line-items td:first-child {
  border-left: none !important;
}
</style>
